//TODO get from constants baased on client
import { GA_TRACKING_ID, GTM_ID } from 'lib/constants';

import _omit from 'lodash/omit';
import Script from 'next/script';

if (GTM_ID && typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
}

/**
 *
 * @param {string} event Name of the event
 * @param {object} properties Event properties
 * @param {object} options Unused, maintained for backwards compatibility
 * @param {function} callback Run upon reporting completion
 */

export async function track(event, properties, options, callback) {
  if (typeof window !== 'undefined') {
    if (GTM_ID && window.dataLayer) {
      /*  https://developers.google.com/tag-platform/devguides/datalayer#reset
          Since next doesn't reload page on every route change we need to
          explicity reset the dataLayer
      */
      window.dataLayer.push(function () {
        this.reset();
      });
      window.dataLayer.push({
        event,
        ...properties,
      });
    }

    if (GA_TRACKING_ID && window.gtag) {
      let gaProperties = _omit(properties, [
        'category',
        'label',
        'value',
        'nonInteraction',
      ]);

      window.gtag('event', event, {
        ...gaProperties, // @TODO remove the following 4 fields from this
        ...(properties.category && {
          event_category: properties.category,
        }),
        ...(properties.label && { event_label: properties.label }),
        ...(properties.value && { value: properties.value }),
        ...(properties.nonInteraction && {
          nonInteraction: properties.nonInteraction,
        }),
      });
    } else if (GA_TRACKING_ID) {
      console.log('no window.gtag, could not run', event);
    }

    if (typeof callback === 'function') {
      return callback();
    }
  } else {
    console.log('no window, could not run:', event);
  }
}

/**
 *
 * @param {*} category
 * @param {*} name
 * @param {*} properties
 * @param {*} options
 * @param {*} callback
 */
export function page(category, name, properties, options, callback) {
  /* A dictionary of properties of the page. Note: Analytics.js collects url, title, referrer and path are automatically. This defaults to a canonical url, if available, and falls back to document.location.href.*/

  if (typeof window === 'undefined') {
    return;
  }

  const url = typeof location !== 'undefined' ? location.href : null;

  if (GTM_ID) {
    /*  https://developers.google.com/tag-platform/devguides/datalayer#reset
        Since next doesn't reload page on every route change we need to
        explicity reset the dataLayer
    */
    window.dataLayer.push(function () {
      this.reset();
    });
    window.dataLayer.push({
      event: 'pageview',
      page: url,
    });
  }

  if (GA_TRACKING_ID && window.gtag) {
    let gaconfig = {
      page_path: url,
    };

    /*if (user_id !== '') {
      gaconfig.user_id = user_id;
    }*/

    window.gtag('config', GA_TRACKING_ID, gaconfig);
  }
  if (typeof callback === 'function') {
    return callback();
  }
}

/** Generate scripts to load the active libraries. */
export function AnalyticsHead() {
  return (
    <>
      {GTM_ID && (
        <>
          <link
            rel="preconnect"
            href="https://www.google-analytics.com"
            crossOrigin="true"
          />
          <link
            rel="preconnect"
            href="https://www.googletagmanager.com"
            crossOrigin="true"
          />
          {/* eslint-disable-next-line */}
          <script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
            }}
          />
        </>
      )}
      {GA_TRACKING_ID && (
        <>
          <link
            rel="preconnect"
            href="https://www.googletagmanager.com"
            crossOrigin="true"
          />
          <link
            rel="preconnect"
            href="https://www.google-analytics.com"
            crossOrigin="true"
          />
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
          />
          <Script
            id="gtag-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${GA_TRACKING_ID}', { page_path: window.location.pathname });
              `,
            }}
          />
        </>
      )}
    </>
  );
}

/** Body markup (often noscript) */
export function AnalyticsBody() {
  return (
    <>
      {GTM_ID && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      )}
    </>
  );
}

const analytics = {
  page,
  track,
  AnalyticsHead,
  AnalyticsBody,
};

export default analytics;
