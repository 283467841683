export const WORDPRESS_DOMAIN = process.env.WORDPRESS_DOMAIN;

// for local use,  make sure WORDPRESS_DOMAIN is defined in your .env
export const WORDPRESS_URL = WORDPRESS_DOMAIN
  ? WORDPRESS_DOMAIN.includes('localhost')
    ? 'http://' + WORDPRESS_DOMAIN
    : 'https://' + WORDPRESS_DOMAIN
  : '';

export const WORDPRESS_API_URL =
  process.env.WORDPRESS_API_URL ||
  (WORDPRESS_URL && WORDPRESS_URL + '/graphql') ||
  'https://wearehome.wpengine.com/graphql';

export const META_TITLE = 'We Are Home';
export const META_DESCRIPTION =
  'Millions of undocumented immigrants live, work, and contribute to the United States every day. It is long overdue for Congress to formally recognize immigrants with citizenship';
export const META_OG_IMAGE_URL =
  'https://res.cloudinary.com/patronage/image/upload/v1611591542/wah/2021/01/WAH.png';

export const META = {
  siteName: 'We Are Home',
  titleAppend: '| We Are Home',
  url: 'https://www.wearehome.us/',
  //twitterHandle: '@wearehome',
};

export const INTERNAL_DOMAINS = [
  'wearehome.us',
  'www.wearehome.us',
  'wearehome.wpengine.com',
  'wearehomes.wpengine.com',
];

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
