import { RewriteFrames } from '@sentry/integrations';
import * as Sentry from '@sentry/node';
import analytics from 'lib/analytics';
import { META } from 'lib/constants';
import UTM from 'lib/utm';
import { DefaultSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import 'styles/global.scss';

export function reportWebVitals(metric) {
  // The metric object ({ id, name, startTime, value, label })
  analytics.track('coreWebVitals', {
    // Built-in params:
    name: metric.name,
    value: metric.value,
    // Custom params:
    metric_id: metric.id, // Needed to aggregate events.
    metric_category:
      metric.label === 'web-vital'
        ? 'Web Vitals'
        : 'Next.js custom metric',
  });
}

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const integrations = [];
  if (
    process.env.NEXT_IS_SERVER === 'true' &&
    process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
  ) {
    // For Node.js, rewrite Error.stack to use relative paths, so that source
    // maps starting with ~/_next map to files in Error.stack with path
    // app:///_next
    integrations.push(
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(
            process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
            'app:///',
          );
          frame.filename = frame.filename.replace('.next', '_next');
          return frame;
        },
      }),
    );
  }

  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.NEXT_PUBLIC_COMMIT_SHA,
  });
}

export default function App({ Component, pageProps, err }) {
  const router = useRouter();

  useEffect(() => {
    // Google analytics:
    // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js
    const handleRouteChange = () => {
      analytics.page();
      // this will save all params to localStorage
      UTM.save(UTM.parse());
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    // You can set sitewide <head> tags in the <Meta> component
    <>
      <DefaultSeo
        // titleTemplate={`%s | ${META.siteName}`}
        defaultTitle={META.siteName}
        openGraph={{
          type: 'website',
          locale: 'en_US',
          site_name: META.siteName,
        }}
        twitter={{
          handle: META.twitterHandle,
          cardType: 'summary_large_image',
        }}
      />
      <Component {...pageProps} err={err} />
    </>
  );
}
